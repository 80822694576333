window.$ = window.jQuery = require('jquery');
require('./bootstrap');
require('slick-carousel');

$(document).ready(function(){
    $('.slick').slick({
        dots: true,
    });

    $("#input_link").click(function () {
        $(this).select();
    });

    $("#textarea_embed").click(function () {
        $(this).select();
    });

    $("#button_copy_embed").click(function(){
        $("#textarea_embed").select();
        document.execCommand("copy");
        $('#textarea_embed').tooltip({'trigger': 'manual', 'title': 'Embed code copied to Clipboard!'}).tooltip('show');
        setTimeout(function(){
            $('#textarea_embed').tooltip('hide');
        }, 2000);
    });

});